.img-topbar{
    height: 3em;
}

.batch-card {
    min-width: 20em;
    max-height: 8em;
    border-style: solid;
    border-color: #F4F4F4;
    border-width: 0.2em;
    border-radius:0.5em;
    padding:1em;
    margin:0.1em;
}